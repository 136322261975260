import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Helmet } from 'react-helmet';
import { Grommet, Box } from 'grommet';
import styled from 'styled-components';
import { Pharmacy } from 'schema-dts';

import Footer from './Footer';
import GlobalStyles from '../../utils/global-styles';
import theme from '../../utils/theme';
import HeaderAnimated from './HeaderAnimated';
import Menu from './Menu';
import TopContent from './TopContent';
import { AppContextProvider } from '../AppContext';
import JsonLd from '../../utils/json-ld';
import logo from '../../assets/favicon-512.png';
import photo from '../../assets/hirschapotheke.jpg';

type Props = {
  children: React.ReactNode;
};

export default function Layout({ children }: Props) {
  const { site, allJavascriptFrontmatter } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          description
          language
        }
      }
      allJavascriptFrontmatter(
        sort: { fields: frontmatter___menu }
        filter: { frontmatter: { menu: { ne: null } } }
      ) {
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              title
              error
            }
          }
        }
      }
    }
  `);
  const pages = allJavascriptFrontmatter.edges.map(({ node }: any) => {
    return { title: node.frontmatter.title, slug: (node.fields || {}).slug };
  });

  return (
    <Grommet theme={theme}>
      <GlobalStyles />
      <Helmet
        titleTemplate={`%s | ${site.siteMetadata.title}`}
        defaultTitle={site.siteMetadata.title}
      >
        <html lang={site.siteMetadata.language} />
        <meta name="description" content={site.siteMetadata.description} />
        <meta name="zipcode" content="34497" />
        <meta name="city" content="Korbach" />
        <meta name="country" content="Germany" />
        <meta name="geo.region" content="DE-HE" />
        <meta name="geo.placename" content="Korbach" />
        <meta name="geo.position" content="51.273261;8.87103" />
        <meta name="icbm" content="51.273261, 8.87103" />
      </Helmet>
      <JsonLd<Pharmacy>>
        {{
          '@context': 'https://schema.org',
          '@type': 'Pharmacy',
          name: 'Hirsch-Apotheke',
          slogan: 'Im Dienste Ihrer Gesundheit',
          url: 'https://hirsch-apotheke.korbach.de',
          telephone: '05631 / 2545',
          faxNumber: '05631 / 913 923',
          address: {
            '@type': 'PostalAddress',
            addressCountry: 'DE',
            postalCode: '34497',
            streetAddress: 'Prof.-Kümmell-Straße 2',
          },

          geo: {
            '@type': 'GeoCoordinates',
            latitude: 51.273261,
            longitude: 8.87103,
          },
          openingHours: ['Mo-Fr 8:30-18:00', 'Sa 9:00-13:00'],
          currenciesAccepted: 'EUR',
          priceRange: '$$',
          logo,
          image: logo,
          photo,
        }}
      </JsonLd>
      <AppContextProvider>
        <Box gap="small">
          <HeaderContainer>
            <HeaderAnimated />
            <SubHeader>
              <Menu pages={pages} />
              <TopContent />
            </SubHeader>
          </HeaderContainer>
          <MainContainer>{children}</MainContainer>
          <FooterContainer />
        </Box>
      </AppContextProvider>
    </Grommet>
  );
}

const HeaderContainer = styled(Box)`
  position: relative;
  z-index: 2;
`;

const SubHeader = styled(Box)`
  padding: 10px;
`;

const MainContainer = styled(Box)`
  position: relative;
  z-index: 1;
  min-height: 500px;
  padding: 10px;
  text-align: center;
`;

const FooterContainer = styled(Footer)`
  position: relative;
  z-index: 1;
`;
